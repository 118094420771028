<script>
	import { algoliasearch } from 'algoliasearch';
	// import aa from 'search-insights';
	export let searchSvgUrl;

	const applicationID = 'PSITTX73L0';
	const apiKey = 'ed3a37636a9aac2beb695c70eb33f721'; // includes wcu wp searchable posts and suggested queries
	const algoliaClient = algoliasearch(applicationID, apiKey);
	console.log(algoliaClient);

	window.aa('init', {
		appId: applicationID,
		apiKey: apiKey,
	});

	console.log(aa);

	// Populate query suggestions on component mount
	let querySuggestions = [];
	(async () => {
		try {
			const response = await algoliaClient.searchSingleIndex({
				indexName: 'wcu_wp_searchable_posts_query_suggestions',
			});
			querySuggestions = response.hits.slice(0, 8);
		} catch (error) {
			console.error('Error fetching query suggestions:', error);
		}
	})();
	console.log(querySuggestions);

	let searchInputValue = '';
	let queryID = '';
	let hits = [];
	const performSearch = async (query, source) => {
		try {
			if (source === 'suggestions') {
				searchInputValue = query;
			}
			const response = await algoliaClient.searchSingleIndex({
				indexName: 'wcu_wp_searchable_posts',
				searchParams: {
					query: query,
					clickAnalytics: true,
				},
			});
			console.log('response = ', response);
			queryID = response.queryID;
			hits = response.hits;
			console.log(hits);
		} catch (error) {
			console.error('Error fetching search results:', error);
		}
	};

	const parseHitType = (hit) => {
		let hitType;
		if (hit.is_program_page === 1) {
			hitType = 'Program';
		} else if (hit.url.paths.path_2 === 'campuses') {
			hitType = 'Campus';
		} else if (hit.post_type_label === 'Posts') {
			hitType = 'Blog';
		} else if (hit.post_type_label === 'Pages') {
			hitType = '';
		} else {
			hitType = hit.post_type_label;
		}
		return hitType;
	};

	const searchResultClicked = (hit) => {
		const objectIDs = [hit.objectID];
		const positions =
			[hits.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(hit)) + 1];

		window.aa('clickedObjectIDsAfterSearch', {
			index: 'wcu_wp_searchable_posts',
			eventName: 'Search Result Clicked',
			queryID,
			objectIDs,
			positions,
		});

		sessionStorage.setItem('algoliaQueryID', queryID);
		sessionStorage.setItem('algoliaObjectID', JSON.stringify(objectIDs));
	};
</script>

<div id="search-wrap" class="search-wrapper">
	<label for="search-input">
		Search
		<img src={searchSvgUrl} alt="" />
		<input
			id="search-input"
			type="text"
			placeholder="Search"
			aria-label="Search"
			class=""
			name="s"
			bind:value={searchInputValue}
			on:input={() => performSearch(searchInputValue, 'searchBar')}
		/>
	</label>
	<button
		class="search-close"
		on:click={() =>
			setTimeout(() => {
				searchInputValue = '';
			}, 400)}><span>Close Search</span></button
	>
	<ul id="search-ui">
		<li>
			<ul id="suggested-terms">
				{#each querySuggestions as suggestion}
					<li class="suggested-term">
						<button
							class="suggested-term-btn"
							on:click={() => performSearch(suggestion.query, 'suggestions')}
							>{suggestion.query}</button
						>
					</li>
				{/each}
			</ul>
		</li>
		<li id="results-container">
			<ul id="search-results">
				{#if hits.length === 0 || searchInputValue === ''}
					<li id="placeholder">What can we help you find?</li>
				{:else}
					{#each hits as hit}
						<li>
							<p class="hit-type">{parseHitType(hit)}</p>
							<a href={hit.url.path} on:click={() => searchResultClicked(hit)}
								><h2>{hit.post_title}</h2>
								<p>
									{@html hit._highlightResult.meta_desc_truncated.matchedWords
										.length > 0
										? hit._highlightResult.meta_desc_truncated.value
										: hit.meta_desc_truncated}
								</p>
							</a>
						</li>
					{/each}
				{/if}
			</ul>
		</li>
	</ul>
</div>
